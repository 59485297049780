import React from "react"
import { Link } from "gatsby"
const Footer = () => {
  return (
    <div className="footer">
      <hr></hr>
      <div className="contact-footer">
        <p>
          Ihr Restaurant ist noch nicht auf der Liste oder Angaben sind falsch? Kontaktieren Sie uns einfach per{" "}
          <a className="btn" href="mailto:sindelfingenviews@gmail.com">Mail</a>
        </p>
      </div>
      <p>
        {" "}
        <Link to="/impressum">Impressum</Link> | <Link to="/datenschutz">Datenschutz</Link>{" "}
      </p>
    </div>
  )
}
export default Footer
