import React, { useEffect, useState } from "react"

const OpenTime = props => {
  const [satz, setSatz] = useState("")

  useEffect(() => {
    var current = new Date()
    var day = current.getDay()

    if (props.times[day].store_times.raw.length <= 2) {
      const openingtime = parseFloat(props.times[day].store_times.raw[0].text)
      const closetime = parseFloat(props.times[day].store_times.raw[1].text)
      var currentTime = current.getHours() + current.getMinutes() / 60
      if (openingtime >= 0 && openingtime < currentTime && closetime > currentTime) {
        setSatz("Geöffnet")
      } else {
        setSatz("Geschlossen")
      }
    } else {
      const openingtime = parseFloat(props.times[day].store_times.raw[0].text)
      const closetime = parseFloat(props.times[day].store_times.raw[1].text)
      const openingtime1 = parseFloat(props.times[day].store_times.raw[2].text)
      const closetime2 = parseFloat(props.times[day].store_times.raw[3].text)
      var currentTime = current.getHours() + current.getMinutes() / 60
      if (
        (openingtime >= 0 && openingtime < currentTime && closetime > currentTime) ||
        (currentTime > openingtime1 && currentTime < closetime2)
      ) {
        setSatz("Geöffnet")
      } else {
        setSatz("Geschlossen")
      }
    }

    /* if (
      (openTime[day].open >= 0 && openTime[day].open < currentTime && openTime[day].close > currentTime) ||
      (currentTime > openTime[day].open1 && currentTime < openTime[day].close2)
    ) {
      setSatz("Geöffnet")
    } else {
      setSatz("Geschlossen")
    }

    */
  })

  return (
    <div
      className={props.show === "long" ? "opening-long" : props.show === "short-map" ? "opening-map" : "opening-short"}
    >
      <p className={satz === "Geöffnet" ? "open" : "closed"}>● {satz}</p>
    </div>
  )
}
export default OpenTime
