/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import CookieConsent from "react-cookie-consent"
import { Link } from "gatsby"

import "../assets/scss/styles.scss"

const Layout = ({ children }) => {
  return (
    <>
      <div>
        <main>
          {children}
          <CookieConsent
            debug={false}
            buttonText="Verstanden"
            expires={90}
            style={{ alignItems: "center", backgroundColor: "#484848" }}
            contentStyle={{ lineHeight: "1.5em" }}
            buttonStyle={{
              backgroundColor: "white",
              color: "#484848",
              fontFamily: "Poppins",
              borderRadius: "10px",
              cursor: "pointer",
              padding: "10px 15px",
            }}
          >
            Wir verwenden ausgewählte Cookies um unsere Website stetig für Sie zu verbessern. Durch Nutzung derselben
            erklären Sie sich damit einverstanden.
            <small>
              <Link to="/datenschutz" className="consentlink">
                Mehr dazu
              </Link>
            </small>
          </CookieConsent>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
