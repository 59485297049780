import React from "react"
import { Link } from "gatsby"

import Logo from "../images/logo.svg"

const Nav = () => {
  return (
    <div>
      <div className="nav">
        <Link to="/">
          <img src={Logo}></img>
        </Link>
        <div>
          <Link activeStyle={{ textDecoration: "underline" }} className="nav__about" to="/angebote">
            Angebote
          </Link>
          <Link activeStyle={{ textDecoration: "underline" }} className="nav__about" to="/about">
            Über Uns
          </Link>
        </div>
      </div>
    </div>
  )
}
export default Nav
